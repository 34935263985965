import {ReactElement} from "react";

type FamilyDownloadProps = {
    data: any
}

const FamilyDownload = ({data}: FamilyDownloadProps): ReactElement => {

    const onBtnClick = (url: string): void => {
        // Open link
        window.open(url, '_blank');
    }

    return (
        <div id='family-download'>
            {
                data.doc_draw
                    ? <p>
                        <button
                            className="btn btn-primary btn-lg w-100 text-white"
                            onClick={() => onBtnClick(data.doc_draw)}
                        >
                            <i className="fas fa-file-pdf"/> Disegno<br/>
                            <small>(Draw, Design, Conception)</small>
                        </button>
                    </p>
                    : <></>
            }
            {
                data.doc_spare
                    ? <p>
                        <button
                            className="btn btn-primary btn-lg w-100 text-white"
                            onClick={() => onBtnClick(data.doc_spare)}
                        >
                            <i className="fas fa-file-pdf"/> Scheda Ricambi<br/>
                            <small>(Spare parts tab, Registerkarte Ersatzteile, Onglet pièces de rechange)</small>
                        </button>
                    </p>
                    : <></>
            }
        </div>
    )
}

export default FamilyDownload;