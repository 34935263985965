import {ReactElement} from "react";

type FamilyDetailProps = {
    data: any
}

const FamilyDetail = ({data}: FamilyDetailProps): ReactElement => {
    return (
        <div id='family-detail'>
            {/* Family image */}
            <img src={data.img_cat} alt="Family" className='img-fluid mb-3'/>
            {/* Detail */}
            {data.desc_cat_it ? <p className='fs-2 mb-1 text-primary'>{data.desc_cat_it}</p> : <></>}
            {data.desc_cat_en ? <p className='mb-0'>{data.desc_cat_en}</p> : <></>}
            {data.desc_cat_fr ? <p className='mb-0'>{data.desc_cat_fr}</p> : <></>}
            {data.desc_cat_es ? <p className='mb-0'>{data.desc_cat_es}</p> : <></>}
            {data.desc_cat_de ? <p className='mb-0'>{data.desc_cat_de}</p> : <></>}
        </div>
    )
}

export default FamilyDetail;