import {ReactElement, useEffect, useRef, useState} from "react";
import FamilyDetail from "../../components/familydetail";
import FamilyDownload from "../../components/familydownload";
import api from "../../api";

const Home = (): ReactElement => {
    // Get query params
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = useRef<any>(Object.fromEntries(urlSearchParams.entries()));

    const [data, setData] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (params.current.id) {
            // Remove error
            setError(null);
            // Load data
            api.get('/code/' + params.current.id).then((result: any) => {
                // Set data
                setData(result.data.data);
            }).catch(() => setError("Si e' verificato un errore."));
        }
    }, [params]);

    return (
        <div id='home'>
            {
                data
                    ? <>
                        {/* Current code */}
                        <div className='py-4'>
                            <span className='fs-2 bg-secondary rounded-pill py-2 px-4 text-white'>
                                Cod. {data.cd_art}
                            </span>
                        </div>
                        {/* Detail */}
                        <div className="row">
                            {/* Family detail */}
                            <div className="col-md-6">
                                <FamilyDetail data={data}/>
                            </div>
                            {/* Download buttons */}
                            <div className="col-md-6">
                                <hr className='d-md-none'/>
                                <FamilyDownload data={data}/>
                            </div>
                        </div>
                    </>
                    : <div className='d-flex justify-content-center align-items-center' style={{height: '50vh'}}>
                        {
                            params.current.id
                                ? <h3>{error || <>Caricamento <i className="fas fa-spinner fa-spin"/></>}</h3>
                                : <>Nessun codice inserito</>
                        }
                    </div>
            }
        </div>
    )
}

export default Home;