import { ReactElement } from "react";
import Home from "../home";

const Layout = (): ReactElement => {
    return (
        <div id='layout'>
            {/* Navbar */}
            <nav className="navbar navbar-dark bg-primary px-2">
                {/* Logo */}
                <img src="assets/logo.svg" alt="FAD ASSALI" width='200'/>
            </nav>
            {/* Content */}
            <div className="container-fluid">
                <Home />
            </div>
            {/* Software version */}
            <div className="text-secondary">
                <small>{process.env.REACT_APP_VERSION}</small>
            </div>
        </div>
    )
}

export default Layout;

